import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Icon, Strong, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaCheckDouble } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Cityscape Drive Rentals
			</title>
			<meta name={"description"} content={"Our selection of cars matches the rhythm of city life, offering a perfect blend of mobility and style. Each rental here is an opportunity to immerse yourself in the heart of the city with ease and elegance.\n"} />
			<meta property={"og:title"} content={"Cityscape Drive Rentals"} />
			<meta property={"og:description"} content={"Our selection of cars matches the rhythm of city life, offering a perfect blend of mobility and style. Each rental here is an opportunity to immerse yourself in the heart of the city with ease and elegance.\n"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65cc7c6d1d712a0023302feb/images/1-1.jpg?v=2024-02-14T11:57:57.489Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65cc7c6d1d712a0023302feb/images/1-1.jpg?v=2024-02-14T11:57:57.489Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65cc7c6d1d712a0023302feb/images/1-1.jpg?v=2024-02-14T11:57:57.489Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65cc7c6d1d712a0023302feb/images/1-1.jpg?v=2024-02-14T11:57:57.489Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65cc7c6d1d712a0023302feb/images/1-1.jpg?v=2024-02-14T11:57:57.489Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65cc7c6d1d712a0023302feb/images/1-1.jpg?v=2024-02-14T11:57:57.489Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65cc7c6d1d712a0023302feb/images/1-1.jpg?v=2024-02-14T11:57:57.489Z"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				Service Overview
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				Cityscape Drive Rentals offers a full range of services designed to meet your city exploration needs. From a diverse fleet of cars suitable for any style and purpose to personalized city tours, we strive to provide a seamless and enriching experience of the city.
			</Text>
		</Section>
		<Section
			padding="68px 0 88px 0"
			sm-padding="60px 0 60px 0"
			box-shadow="--m"
			background="--color-darkL1"
			md-padding="45px 0 45px 0"
			quarkly-title="Advantages/Features-23"
		>
			<Override slot="SectionContent" max-width="1220px" align-items="center" sm-min-width="280px" />
			<Box
				width="100%"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				margin="0px 0px 64px 0px"
				display="flex"
				lg-width="100%"
				align-items="center"
				lg-margin="0px 0px 48px 0px"
				md-margin="0px 0px 24px 0px"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--light"
					md-text-align="center"
					sm-font="normal 700 28px/1.2 --fontFamily-sans"
					text-align="center"
					md-font="--headline3"
				>
					Our Fleet
				</Text>
			</Box>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="16px"
				lg-grid-gap="16px"
				md-grid-template-columns="1fr"
				max-width="1000px"
			>
				<Box
					display="flex"
					padding="46px 46px 47px 46px"
					background="--color-darkL2"
					flex-direction="column"
					justify-content="flex-start"
					box-shadow="--l"
					align-items="center"
					lg-padding="24px 24px 24px 24px"
					border-radius="6px"
				>
					<Icon
						margin="0px 0px 16px 0px"
						color="--light"
						size="64px"
						category="fa"
						icon={FaCheckDouble}
						lg-font="48px sans-serif"
					/>
					<Text font="--lead" margin="0px 0px 12px 0px" color="--light" text-align="center">
						Compact City Cars
					</Text>
					<Text
						flex="1 0 auto"
						margin="0px 0px 0px 0px"
						color="--light"
						font="--base"
						text-align="center"
					>
						Perfect for navigating narrow streets and finding parking with ease. Ideal for solo travelers or couples.
					</Text>
				</Box>
				<Box
					justify-content="flex-start"
					flex-direction="column"
					padding="46px 46px 47px 46px"
					background="--color-darkL2"
					display="flex"
					box-shadow="--l"
					align-items="center"
					lg-padding="24px 24px 24px 24px"
					border-radius="6px"
				>
					<Icon
						icon={FaCheckDouble}
						margin="0px 0px 16px 0px"
						color="--light"
						size="64px"
						category="fa"
						lg-font="48px sans-serif"
					/>
					<Text color="--light" font="--lead" margin="0px 0px 12px 0px" text-align="center">
						Luxury Sedans
					</Text>
					<Text
						font="--base"
						flex="1 0 auto"
						margin="0px 0px 0px 0px"
						color="--light"
						text-align="center"
					>
						Elevate your city travel with our selection of luxury sedans, combining comfort and elegance for special occasions.
					</Text>
				</Box>
				<Box
					display="flex"
					background="--color-darkL2"
					justify-content="flex-start"
					flex-direction="column"
					padding="46px 46px 47px 46px"
					box-shadow="--l"
					align-items="center"
					lg-padding="24px 24px 24px 24px"
					border-radius="6px"
				>
					<Icon
						size="64px"
						category="fa"
						icon={FaCheckDouble}
						margin="0px 0px 16px 0px"
						color="--light"
						lg-font="48px sans-serif"
					/>
					<Text margin="0px 0px 12px 0px" color="--light" font="--lead" text-align="center">
						SUVs and Minivans
					</Text>
					<Text
						flex="1 0 auto"
						margin="0px 0px 0px 0px"
						color="--light"
						font="--base"
						text-align="center"
					>
						For family outings or group adventures, choose our spacious SUVs and minivans to ensure everyone travels in comfort.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-2">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				md-grid-template-columns="1fr"
				md-grid-gap="36px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 20px 0px" font="--lead">
						<Strong>
							Cultural Exploration
						</Strong>
					</Text>
					<Image
						src="https://uploads.quarkly.io/65cc7c6d1d712a0023302feb/images/3-1.jpg?v=2024-02-14T11:57:57.501Z"
						display="block"
						margin="0px 0px 20px 0px"
						max-width="100%"
						srcSet="https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/3-1.jpg?v=2024-02-14T11%3A57%3A57.501Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/3-1.jpg?v=2024-02-14T11%3A57%3A57.501Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/3-1.jpg?v=2024-02-14T11%3A57%3A57.501Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/3-1.jpg?v=2024-02-14T11%3A57%3A57.501Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/3-1.jpg?v=2024-02-14T11%3A57%3A57.501Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/3-1.jpg?v=2024-02-14T11%3A57%3A57.501Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65cc7c6d1d712a0023302feb/images/3-1.jpg?v=2024-02-14T11%3A57%3A57.501Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="--dark">
						Discover the rich history and vibrant culture of the city with routes that take you through iconic landmarks, museums, and hidden gems.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 20px 0px" font="--lead">
						<Strong>
							Nightlife Tours
						</Strong>
					</Text>
					<Image
						src="https://images.unsplash.com/photo-1592399743970-da5938ceee5d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						display="block"
						margin="0px 0px 20px 0px"
						max-width="100%"
						srcSet="https://images.unsplash.com/photo-1592399743970-da5938ceee5d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1592399743970-da5938ceee5d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1592399743970-da5938ceee5d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1592399743970-da5938ceee5d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1592399743970-da5938ceee5d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1592399743970-da5938ceee5d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1592399743970-da5938ceee5d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="--dark">
						Experience the city after dark with our nightlife tours that guide you to the best entertainment spots and panoramic city views.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 20px 0px" font="--lead">
						<Strong>
							Bike Rentals
						</Strong>
					</Text>
					<Image
						src="https://images.unsplash.com/photo-1532298229144-0ec0c57515c7?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						display="block"
						margin="0px 0px 20px 0px"
						max-width="100%"
						srcSet="https://images.unsplash.com/photo-1532298229144-0ec0c57515c7?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1532298229144-0ec0c57515c7?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1532298229144-0ec0c57515c7?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1532298229144-0ec0c57515c7?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1532298229144-0ec0c57515c7?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1532298229144-0ec0c57515c7?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1532298229144-0ec0c57515c7?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="--dark">
						For those who prefer to ride, we offer high-quality bike rentals, allowing you to enjoy the

 city at a more leisurely pace.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b7ae60009abe001f027d1c"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});